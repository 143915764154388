<template>
  <div>
    <a-form-model :model="form" ref="form" layout="vertical" :rules="rules">
      <!-- <a-form-model-item
        v-if="['DEVELOPER', 'ADMIN'].indexOf(currentRole) > -1"
        label="工厂门店负责人"
      >
        <a-switch
          v-model="form.isShopManage"
          checked-children="是"
          un-checked-children="否"
          @change="changeSwitch"
        />
      </a-form-model-item>
      <a-form-model-item
        v-if="form.isShopManage"
        prop="selectedUser1"
        label="选择工厂门店负责人管辖的用户"
      >
        <a-select
          v-model="form.selectedUser1"
          mode="multiple"
          @change="handleUserChange"
          placeholder="请选择用户"
          show-search
          allowClear
          :maxTagCount="10"
          :maxTagTextLength="12"
          option-filter-prop="children"
          :getPopupContainer="triggerNode => triggerNode.parentNode"
          :filter-option="false"
          @search="input => filterUserption(input, 1)"
        >
          <a-select-option
            :value="item.pkId"
            v-for="item in userlist1"
            :key="item.pkId"
          >
            {{ item.memberName }}
          </a-select-option>
          <span slot="maxTagPlaceholder">
            {{ `等${count1}人` }}
          </span>
        </a-select>
      </a-form-model-item> -->
      <a-form-model-item label="内部管理角色">
        <a-checkbox-group
          v-model="form.type"
          @change="roleChange"
          :disabled="currentRole === 'DEVELOPER'"
        >
          <a-checkbox value="ENGINEER">工程师</a-checkbox>
          <a-popconfirm
            v-if="form.type.indexOf('DEVELOPER') < 0"
            ok-text="确定"
            cancel-text="取消"
            @confirm="confirm"
          >
            <span slot="title"
              >开启后若此用户能访问“设备管理”模块将可查看所有用户和设备信息！</span
            >
            <a-checkbox value="DEVELOPER">开发人员</a-checkbox>
          </a-popconfirm>
          <a-checkbox v-else value="DEVELOPER">开发人员</a-checkbox>
          <a-checkbox value="ADMIN" :disabled="true">管理人员</a-checkbox>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item
        v-if="form.type.indexOf('ENGINEER') > -1"
        label="管辖范围"
      >
        <a-select
          v-model="form.selectedUser2"
          mode="multiple"
          :disabled="currentRole === 'DEVELOPER'"
          @change="handleUserChange"
          placeholder="请选择用户"
          show-search
          allowClear
          :maxTagCount="10"
          :maxTagTextLength="12"
          option-filter-prop="children"
          :getPopupContainer="triggerNode => triggerNode.parentNode"
          :filter-option="false"
          @search="input => filterUserption(input, 2)"
        >
          <a-select-option
            :value="item.pkId"
            v-for="item in userlist2"
            :key="item.pkId"
          >
            {{ item.memberName
            }}{{ item.shopNames.length > 0 ? `(${item.shopNames[0]})` : '' }}
          </a-select-option>
          <span slot="maxTagPlaceholder">
            {{ `等${count2}人` }}
          </span>
        </a-select>
      </a-form-model-item>
      <!-- <span :class="$style.tip">选择管理的门店，支持多选</span> -->
    </a-form-model>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Debounce } from 'lodash-decorators';
import {
  getCanChooseUserList,
  setRole,
  getUserList,
} from '@/services/oven/permission';

@Component({})
export default class EditRole extends Vue {
  @Prop({ type: Object, default: () => {} }) editData;
  @Prop({ type: String, default: '' }) currentRole;
  form = {
    isShopManage: false,
    selectedUser1: undefined,
    selectedUser2: undefined,
    type: [],
  };
  userlist1 = [];
  userlist2 = [];
  created() {
    this.init();
  }
  async init() {
    const list = this.editData;
    this.form.isShopManage = list.isShopManager;
    const userData = await this.getUserData();
    // 工厂门店负责人
    if (list.isShopManager) {
      this.userlist1 = userData.shopManagerMember;
      this.form.selectedUser1 = userData.shopManagerMember.map(v => v.pkId);
    }
    if (list.isAdmin) {
      this.form.type.push('ADMIN');
    }
    if (list.isDevelop) {
      this.form.type.push('DEVELOPER');
    }
    // 工程师
    if (list.isEngineer) {
      this.form.type.push('ENGINEER');
      // this.userlist2 = userData.engineerMember;
      await this.getList('ENGINEER');
      this.form.selectedUser2 = userData.engineerMember.map(v => v.pkId);
    }
  }
  rules = {
    selectedUser1: [
      {
        required: true,
        message: '请选择管辖的用户',
      },
    ],
  };
  async getUserData() {
    try {
      return await getUserList(this.editData.pkId);
    } catch {
      return false;
    }
  }
  async getList(role) {
    const params = {
      memberId: this.editData.pkId,
      memberName:
        this.userSearchInput !== '' ? this.userSearchInput : undefined,
      role,
    };
    try {
      const data = await getCanChooseUserList(params);
      if (data.length > 0) {
        if (role === 'SHOP_MANAGER') {
          this.userlist1 = data.slice(0, 100);
        } else {
          this.userlist2 = data.slice(0, 100);
        }
      }
    } catch {
      return [];
    }
  }
  get count1() {
    return this.form.selectedUser1?.length || '';
  }
  get count2() {
    return this.form.selectedUser2?.length || '';
  }
  changeSwitch(v) {
    this.form.isShopManage = v;
    this.getList('SHOP_MANAGER');
  }
  roleChange(v) {
    if (v.indexOf('ENGINEER') > -1) {
      this.getList('ENGINEER');
    }
    this.form.type = v;
  }
  handleUserChange(v) {
    this.form.selectedUser = v;
  }
  userSearchInput = '';
  @Debounce(300)
  filterUserption(input, flag) {
    const str = input.toLowerCase();
    const role = flag === 1 ? 'SHOP_MANAGER' : 'ENGINEER';
    flag === 1 ? (this.userlist1 = []) : (this.userlist2 = []);
    this.userSearchInput = str;
    this.getList(role);
  }
  confirm() {
    this.form.type.push('DEVELOPER');
  }

  /**
   * @param role 当前设置的角色
   * @param flag 是否设置
   * @param list 提交的管理用户的列表
   * @returns {Promise<boolean>}
   */
  async submitRole(role, flag, list = []) {
    const params = {
      memberId: this.editData.pkId,
      isOpen: flag,
      role,
      manageMemberId: list,
    };
    return new Promise((resolve, reject) => {
      setRole(params)
        .then(res => {
          resolve(res);
        })
        .catch(() => {
          reject(false);
          return false;
        });
    });
  }
  async getValue() {
    await this.$refs.form.validate();
    const promise = [];
    const p1 = this.submitRole(
      'SHOP_MANAGER',
      this.form.isShopManage,
      this.form.selectedUser1,
    );
    promise.push(p1);
    const p2 = this.submitRole(
      'ENGINEER',
      this.form.type.indexOf('ENGINEER') > -1,
      this.form.selectedUser2,
    );
    promise.push(p2);
    const p3 = this.submitRole(
      'DEVELOPER',
      this.form.type.indexOf('DEVELOPER') > -1,
      [],
    );
    promise.push(p3);
    return new Promise((resolve, reject) => {
      Promise.all(promise)
        .then(res => {
          if (res.length > 0) {
            resolve(true);
            this.$message.success('设置成功');
          }
        })
        .catch(() => {
          reject(false);
        });
    });
  }
}
</script>

<style lang="less" module>
:global {
  .ant-btn-sm {
    height: 30px !important;
  }
  .ant-popover-message-title {
    width: 180px;
  }
  .dark {
    .ant-select-disabled,
    .ant-select-selection--multiple,
    .ant-select-selection__choice {
      color: var(--font) !important;
      background: var(--selection-tag-bg) !important;
    }
  }
  .tint .ant-select-disabled .ant-select-selection {
    background: #ebebeb;
  }
}
.tip {
  margin-top: 4px;
  font-size: 12px;
}
</style>
