import request from '../request';

const serverName = '/iot-kcloud/kolb/shop';

/** @name 获取门店列表 */
export function getShopList(data) {
  return request(`${serverName}/page`, {
    method: 'POST',
    body: data,
  });
}

/** @name 新增门店 */
export function createShop(data) {
  return request(`${serverName}/create`, {
    method: 'POST',
    body: data,
  });
}

/** @name 编辑门店 */
export function updateShop(data) {
  return request(`${serverName}/update`, {
    method: 'PUT',
    body: data,
  });
}

/** @name 删除门店 */
export function deleteShop(id) {
  return request(`${serverName}/delete/${id}`, {
    method: 'DELETE',
  });
}
