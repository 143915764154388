<template>
  <div>
    <a-input-search
      :class="$style.top"
      placeholder="请输入门店名称"
      v-model="query"
      @input="event => enterQuery(event)"
    />
    <a-table
      :columns="columns"
      :dataSource="dataList"
      :pagination="pagination"
      :class="$style.table"
      @change="pageChange"
    >
      <template slot="action" slot-scope="record">
        <span :class="$style.edit" @click.stop="editShop(record)">编辑</span>
        <span
          :class="[$style.edit, $style.del]"
          @click.stop="deleteShop(record)"
          >删除</span
        >
      </template>
    </a-table>
  </div>
</template>
<script>
import { createFormModal } from '@triascloud/x-components';
import { Component, Vue } from 'vue-property-decorator';
import Tip from '@/components/tip';
import EditShop from './add.vue';
import { getShopList, deleteShop } from '@/services/oven/shop';
import { Throttle } from 'lodash-decorators';
@Component()
export default class Index extends Vue {
  query = '';

  async fetchData() {
    const { records, total } = await getShopList({
      input: this.query,
      pageNum: this.pagination.current,
    });
    this.dataList = records;
    this.pagination.total = total;
  }
  mounted() {
    this.fetchData();
  }
  @Throttle(200)
  enterQuery() {
    this.pagination.current = 1;
    this.fetchData();
  }

  pagination = {
    current: 1,
    pageSize: 5,
    total: 0,
  };

  dataList = [];
  get columns() {
    return [
      {
        dataIndex: 'shopName',
        title: '门店',
        align: 'center',
        ellipsis: true,
        width: 200,
      },
      {
        align: 'center',
        title: '负责人',
        dataIndex: 'manages',
        customRender: (text, record) => (
          <div>
            {record.manages.slice(0, 2).map(v => (
              <span class={this.$style.margin5}>
                {v.avatar ? (
                  <x-oss-image class={this.$style.avatar} ossPath={v.avatar} />
                ) : (
                  ''
                )}
                <span class={this.$style.margin5}>{v.memberName}</span>
              </span>
            ))}
            {record.manages.length > 2 ? (
              <span class={this.$style.margin5}>等</span>
            ) : (
              ''
            )}
          </div>
        ),
      },
      {
        align: 'center',
        title: '管辖用户',
        dataIndex: 'managedUsers',
        customRender: (text, record) => (
          <div>
            {record.managedUsers.slice(0, 2).map(v => (
              <span class={this.$style.margin5}>
                {v.avatar ? (
                  <x-oss-image class={this.$style.avatar} ossPath={v.avatar} />
                ) : (
                  ''
                )}
                <span class={this.$style.margin5}>{v.memberName}</span>
              </span>
            ))}
            {record.managedUsers.length > 2 ? (
              <span class={this.$style.margin5}>等</span>
            ) : (
              ''
            )}
          </div>
        ),
      },
      {
        align: 'left',
        title: '操作',
        fixed: 'right',
        width: 200,
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  async pageChange(pagination) {
    this.pagination.current = pagination.current;
    await this.fetchData();
  }

  async editShop(record) {
    const flag = await createFormModal(() => <EditShop detail={record} />, {
      width: 520,
      title: '修改门店',
    });
    if (flag) {
      this.fetchData();
    }
  }
  deleteShop(record) {
    createFormModal(
      () => (
        <Tip iconStyle={{ padding: '0 0 22px' }}>
          <template slot="txt">
            <span>确认删除门店{record.shopName}</span>
          </template>
          <span slot="subTxt">
            注意：删除后不影响设备绑定人员，但门店负责人无法管理门店成员绑定的设备！
          </span>
        </Tip>
      ),
      {
        width: 600,
        title: '操作提示',
        onOk: async () => {
          await deleteShop(record.pkId);
          await this.$message.success({
            content: '删除成功',
            duration: 0.5,
          });
          this.fetchData();
        },
      },
    );
  }
}
</script>
<style lang="less" module>
.top {
  margin-bottom: 10px;
}
.table {
  .edit {
    color: var(--font-active);
  }
  .del {
    color: var(--danger);
  }
  .edit + .edit {
    margin-left: 10px;
  }
  .avatar {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    vertical-align: middle;
  }
  .margin5 {
    margin-left: 5px;
  }
  .margin5 + .margin5 {
    margin-left: 5px;
  }
}
</style>
