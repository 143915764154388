<template>
  <div :class="$style.permissionBox">
    <layout-content>
      <template v-slot:header-left>
        <span :class="$style.title">权限管理</span>
      </template>
      <div :class="$style.content" ref="content" slot="content">
        <div :class="$style.form" v-if="currentRole !== 'NORMAL'">
          <a-form-model ref="ruleForm" layout="inline">
            <a-form-model-item>
              <a-input-search
                v-model="searchMemberName"
                placeholder="用户姓名"
                style="width: 200px"
                @search="searchUserName"
              />
            </a-form-model-item>
            <a-form-model-item v-if="currentRole !== 'SHOP_MANAGER'">
              <a-select
                style="width: 200px"
                v-model="searchRole"
                @change="searchUserRole"
              >
                <a-select-option
                  v-for="(item, idx) in userArr"
                  :key="idx"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
          <div>
            <a-dropdown>
              <a-button type="primary" @click="addShop"
                >新增门店<a-icon style="color: #fff" type="down"
              /></a-button>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a-button @click="shopManager">门店管理</a-button>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
        <a-table
          :columns="columns"
          :dataSource="dataList"
          :pagination="pagination"
          :scroll="{ x: 1600 }"
          :class="$style.table"
          @change="handlePageChange"
        >
          <span slot="memberName">
            <span slot="memberName">用户姓名</span>
            <a-tooltip placement="top">
              <template slot="title">
                <span>
                  设备绑定的“当前用户”和拥有“烤箱管理”功能项目的排重成员！
                </span>
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <template slot="memberName" slot-scope="text, record">
            <div style="display: flex;align-items: center;">
              <Avatar :name="text" :img="record.avatar" />
              <a-tooltip placement="top">
                <template slot="title">
                  <span>{{ record.mobilePhone }}</span>
                  <span
                    @click.stop="handleCopy(record)"
                    style="color: var(--primary);margin-left: 10px; cursor: pointer"
                  >
                    点击复制
                  </span>
                </template>
                <x-icon
                  :class="$style.iconStyle"
                  type="tc-icon-dianhua1"
                ></x-icon>
              </a-tooltip>
            </div>
          </template>
          <span slot="bindTitle">
            <span slot="bindTitle">绑定设备台数</span>
            <a-tooltip placement="top">
              <template slot="title">
                <span>
                  自身绑定设备和管理用户的排重设备台数！
                </span>
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <span slot="manageTitle">
            <span slot="manageTitle">管理设备台数</span>
            <a-tooltip placement="top">
              <template slot="title">
                <span>
                  可以管理多个用户的设备！
                </span>
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <template slot="innerManageRole" slot-scope="text">
            <a-tag
              v-if="text.isEngineer"
              style="color:#2db7f5;margin-right: 10px"
            >
              工程师
            </a-tag>
            <a-tag
              v-if="text.isDevelop"
              style="color:#00b3b3;margin-right: 10px"
            >
              开发人员
            </a-tag>
            <a-tag v-if="text.isAdmin" style="color:#c5a763;">
              管理员
            </a-tag>
            <span
              style="padding:6px 7px;"
              v-if="!text.isEngineer && !text.isDevelop && !text.isAdmin"
              >-</span
            >
          </template>
          <template
            v-if="$p.action('UPDATE', '/oven/permission')"
            slot="action"
            slot-scope="record"
          >
            <span :class="$style.edit" @click.stop="editRow(record)">编辑</span>
          </template>
        </a-table>
      </div>
    </layout-content>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
import { createFormModal, createModal } from '@triascloud/x-components';
import EditRole from './edit-role';
import { clipboardWrite } from '@triascloud/utils';
import Avatar from '@/components/avatar';
import { getUserRole, getKolbUserList } from '@/services/oven/permission';
import AddShop from './shop/add.vue';
import Shop from './shop/index.vue';

@Component({
  components: {
    LayoutContent,
    Avatar,
  },
})
export default class Permission extends Vue {
  get userArr() {
    let normal = [
      {
        value: 'ALL',
        label: '全部',
      },
      {
        value: 'NORMAL',
        label: '用户',
      },
      {
        value: 'SHOP_MANAGER',
        label: '工厂门店负责人',
      },
    ];
    const search = [
      {
        value: 'ENGINEER',
        label: '工程师',
      },
      {
        value: 'DEVELOPER',
        label: '开发人员',
      },
      {
        value: 'ADMIN',
        label: '管理人员',
      },
    ];
    if (['ADMIN', 'DEVELOPER'].indexOf(this.currentRole) > -1) {
      normal = normal.concat(search);
    }
    return normal;
  }
  mounted() {
    this.getRoleList();
    this.getTableList();
  }
  async addShop() {
    const result = await createFormModal(() => <AddShop />, {
      width: 520,
      title: '新增门店',
    });
    if (result) {
      this.getTableList();
    }
  }
  shopManager() {
    createModal(() => <Shop />, {
      width: 820,
      title: '门店列表',
    });
  }

  pagination = {
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => this.$t('todo.flow.total', { total }),
  };
  searchUserName(v) {
    this.searchMemberName = v;
    this.pagination.current = 1;
    this.getTableList();
  }
  searchUserRole(v) {
    this.searchRole = v;
    this.pagination.current = 1;
    this.getTableList();
  }
  dataList = [];
  searchMemberName = '';
  searchRole = 'ALL';
  async getTableList() {
    const params = {
      current: this.pagination.current,
      size: this.pagination.pageSize,
      memberName:
        this.searchMemberName !== '' ? this.searchMemberName : undefined,
    };
    if (this.searchRole !== 'ALL') {
      params.role = this.searchRole;
    }
    try {
      const { records, current, total } = await getKolbUserList(params);
      this.dataList = records.map(v => {
        return {
          ...v,
          innerManageRole: {
            isAdmin: v.isAdmin,
            isDevelop: v.isDevelop,
            isEngineer: v.isEngineer,
          },
        };
      });
      this.pagination.current = current;
      this.pagination.total = total;
    } catch {
      return false;
    }
  }
  currentRole = 'NORMAL';
  async getRoleList() {
    try {
      this.currentRole = await getUserRole();
    } catch {
      return false;
    }
  }
  get columns() {
    const normal = [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: '序号',
        align: 'center',
        fixed: 'left',
        width: 80,
      },
      {
        align: 'left',
        dataIndex: 'memberName',
        slots: { title: 'memberName' },
        scopedSlots: { customRender: 'memberName' },
      },
      {
        align: 'left',
        slots: { title: 'bindTitle' },
        dataIndex: 'bindEquipmentNumber',
      },
      {
        align: 'left',
        slots: { title: 'manageTitle' },
        dataIndex: 'managerEquipmentNumber',
      },
    ];
    const shopManager = [
      {
        align: 'left',
        title: '门店负责人',
        dataIndex: 'isShopManager',
        customRender: (value, record) => (
          <div>
            {value ? <div>{this.shopManagerShow(record.kolbShops)}</div> : '-'}
          </div>
        ),
      },
    ];
    const engineer = [
      {
        align: 'left',
        title: '内部管理角色',
        dataIndex: 'innerManageRole',
        scopedSlots: { customRender: 'innerManageRole' },
      },
    ];
    const other = [
      {
        align: 'left',
        title: '操作',
        fixed: 'right',
        width: 200,
        scopedSlots: { customRender: 'action' },
      },
    ];
    let showColumn = [];
    if (this.currentRole === 'NORMAL') {
      showColumn = showColumn.concat(normal);
    }
    if (this.currentRole === 'SHOP_MANAGER') {
      showColumn = showColumn.concat(normal);
    }
    if (this.currentRole === 'ENGINEER') {
      showColumn = showColumn
        .concat(normal)
        .concat(shopManager)
        .concat(engineer);
    }
    if (['DEVELOPER', 'ADMIN'].indexOf(this.currentRole) > -1) {
      showColumn = showColumn
        .concat(normal)
        .concat(shopManager)
        .concat(engineer)
        .concat(other);
    }
    return showColumn;
  }
  shopManagerShow(list) {
    if (list.length > 0) {
      if (list.length > 1) {
        return (
          <a-tooltip placement="top">
            <template slot="title">
              <div>
                <div>{this.shopNames(list)}</div>
              </div>
            </template>
            <span>{list[0].shopName}</span>
          </a-tooltip>
        );
      } else {
        return list[0].shopName;
      }
    } else {
      return '-';
    }
  }
  shopNames(list) {
    let str = list.reduce((v, a) => v + a.shopName + '、', '');
    if (str.slice(-1) === '、') {
      str = str.slice(0, -1);
    }
    return str;
  }
  handlePageChange(pagination) {
    this.pagination.current = pagination.current;
    this.getTableList();
  }
  async handleCopy(item) {
    const str = `姓名：${item.memberName}\n手机号码：${item.mobilePhone}`;
    await clipboardWrite(str);
    this.$message.success('复制成功');
  }
  async editRow(row) {
    const role = this.currentRole;
    const result = await createFormModal(
      () => <EditRole editData={row} currentRole={role} />,
      {
        width: '442px',
        title: '编辑权限',
      },
    );
    if (result) {
      await this.getTableList();
    }
  }
}
</script>
<style lang="less" module>
.title {
  font-size: 16px;
}
.permissionBox {
  flex: 1;
  display: flex;
  overflow: hidden;
  :global {
    .ant-table-wrapper .ant-spin-container .ant-table-body,
    .ant-table-wrapper .ant-table-scroll .ant-table-body {
      flex: 1;
      overflow: auto !important;
      max-height: none !important;
    }
  }

  .content {
    padding: 20px 28px;
    min-width: 850px;
    .form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      :global(.ant-form) {
        display: flex;
        align-items: center;
      }
      .btn {
        margin-right: 30px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .iconStyle {
      cursor: pointer;
      color: var(--primary);
    }
    .edit {
      cursor: pointer;
      color: var(--primary);
    }
  }
}
</style>
