<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item label="门店名称" prop="name">
      <a-input
        v-model="form.name"
        :maxLength="32"
        placeholder="门店名称，禁止重复，限32字符长度"
      >
      </a-input>
    </a-form-model-item>
    <a-form-model-item label="门店负责人" prop="manager">
      <a-select v-model="form.manager" mode="multiple" @change="managerChange">
        <a-select-option
          v-for="item in managerList"
          :key="item.pkId"
          :value="item.pkId"
        >
          {{ item.memberName }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="管辖用户" prop="user">
      <a-select v-model="form.user" mode="multiple">
        <a-select-option
          v-for="item in userList"
          :key="item.pkId"
          :value="item.pkId"
        >
          {{ item.memberName }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  getKolbUserList,
  postCanChooseUserList,
} from '@/services/oven/permission';
import { createShop, updateShop } from '@/services/oven/shop';

@Component()
export default class Add extends Vue {
  form = {
    name: '',
    manager: [],
    user: [],
  };

  rules = {
    name: [
      {
        required: true,
        message: '门店名称不能为空',
      },
    ],
    manager: [
      {
        required: true,
        message: '门店负责人不能为空',
      },
    ],
    user: [
      {
        required: true,
        message: '管辖用户不能为空',
      },
    ],
  };

  managerList = [];
  userList = [];

  async fetchData() {
    const { records } = await getKolbUserList({
      size: 200,
    });
    this.managerList = records;
  }

  async managerChange() {
    if (this.form.manager.length === 0) {
      this.userList = [];
    } else {
      this.userList = await postCanChooseUserList({
        memberIds: this.form.manager,
        role: 'SHOP_MANAGER',
      });
    }
  }

  async mounted() {
    if (this.detail && this.detail.pkId) {
      this.form.name = this.detail.shopName;
      this.form.manager = this.detail.manages.map(v => v.pkId);
      await this.managerChange();
      this.form.user = this.detail.managedUsers.map(v => v.pkId);
    }
    this.fetchData();
  }

  @Prop() detail;

  async getValue() {
    await this.$refs.form.validate();
    let params = {
      shopName: this.form.name,
      shopManages: this.form.manager,
      users: this.form.user,
      pkId: '',
    };
    let tip = '';
    if (this.detail && this.detail.pkId) {
      params.pkId = this.detail.pkId;
      await updateShop(params);
      tip = '编辑成功！';
    } else {
      await createShop(params);
      tip = '添加成功！';
    }
    await this.$message.success({
      content: tip,
      duration: 0.5,
    });
    return true;
  }
}
</script>
<style lang="less" module></style>
